import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

function createApolloClient() {
  const httpLink = new HttpLink({
    uri: publicRuntimeConfig.GRAPHQL_URL,
  });

  const cache = new InMemoryCache();

  const link = ApolloLink.from([httpLink]);

  return new ApolloClient({
    link,
    cache,
    name: publicRuntimeConfig.APP_NAME,
    version: publicRuntimeConfig.APP_VERSION,
  });
}

const ApolloClientInstance = createApolloClient();

export default ApolloClientInstance;
