import {
  Config,
  EventPayload,
  setUserProps,
  init,
  logEvent as _logEvent,
  getInstance,
} from '@care/amplitude';
import Cookies from 'universal-cookie';
import { LDEvaluationDetail } from 'launchdarkly-node-server-sdk';
import getConfig from 'next/config';
import { captureException, configureScope } from '@sentry/nextjs';
import {
  CZEN_VISITOR_COOKIE_KEY,
  CZEN_SESSION_COOKIE_KEY,
  CLIENT_SIDE_ERROR_TAG,
  CARE_DEVICE_ID_COOKIE_KEY,
} from '@/constants';
import logger from '@/lib/clientLogger';

const { publicRuntimeConfig } = getConfig();

const cookies = new Cookies();

let initialized = false;
let czenVisitorId: string;
let czenSessionId: string;
let deviceId: string;

function getCzenSessionId() {
  return cookies.get(CZEN_SESSION_COOKIE_KEY);
}

function getCzenVisitorId() {
  return cookies.get(CZEN_VISITOR_COOKIE_KEY);
}

function getCareDeviceId() {
  return cookies.get(CARE_DEVICE_ID_COOKIE_KEY);
}

function getCommonData() {
  const commonData: any = {
    environment: publicRuntimeConfig.AMPLITUDE_ENV,
    rendered_by: 'vhp-mfe',
    czen_session_id: czenSessionId || '',
    visitor_id: czenVisitorId || '',
    care_device_id: deviceId || '',
  };
  return commonData;
}

const initialize = () => {
  if (!initialized) {
    czenVisitorId = getCzenVisitorId();
    czenSessionId = getCzenSessionId();
    deviceId = getCareDeviceId();

    const careAnalyticsConfig: Config = {
      analyticsEnabled: publicRuntimeConfig.ANALYTICS_ENABLED === 'true',
      key: publicRuntimeConfig.AMPLITUDE_API_KEY,
      commonData: getCommonData(),
    };
    init(careAnalyticsConfig);
    initialized = true;

    // session id is available with a small delay after amplitude initialization
    setTimeout(() => {
      const amplitudeSessionId = getInstance()?.getSessionId();

      if (amplitudeSessionId) {
        configureScope((scope) => {
          const user = scope.getUser();
          scope.setUser({
            ...user,
            amplitudeSessionId,
          });
        });
      }
    });
  }
};

export namespace AnalyticsHelper {
  export const logEvent = (payload: EventPayload) => {
    try {
      const isDesktop = window.innerWidth > 960;
      initialize();
      const logEventPayload = {
        name: payload.name,
        data: {
          ...payload.data,
          viewport_resolution: `${window.innerWidth} X  ${window.innerWidth}`,
          screen_resolution: `${window.screen.width} X ${window.screen.height}`,
          referrer: document.referrer,
          web_platform: isDesktop ? 'Desktop Web' : 'Mobile Web',
        },
      };

      _logEvent(logEventPayload);
    } catch (e: any) {
      logger.error({ error: e, tags: [CLIENT_SIDE_ERROR_TAG] });
    }
  };

  /* istanbul ignore next */
  export const setDefaultUserProps = (careDeviceId: string) => {
    try {
      initialize();
      const userProps: any = {};
      if (czenSessionId) {
        userProps.czen_session_id = czenSessionId;
      }
      if (czenVisitorId) {
        userProps.visitor_id = czenVisitorId;
      }

      userProps.care_device_id = careDeviceId;
      setUserProps({
        props: userProps,
      });
    } catch (e: any) {
      logger.error({ error: e, tags: [CLIENT_SIDE_ERROR_TAG] });
      captureException(e);
    }
  };

  export const logTestExposure = (testName: string, evaluationDetail: LDEvaluationDetail) => {
    // only log the exposure if the user matched the flag's inclusion rules,
    // in which case we also know that we have a non-null variationIndex since LD requires a variation
    // to be specified for each rule
    const loggableTestExposureReasons = ['RULE_MATCH', 'OVERRIDE'];
    if (loggableTestExposureReasons.includes(evaluationDetail.reason?.kind)) {
      logEvent({
        name: 'Test Exposure',
        data: {
          test_name: testName,
          test_variant: evaluationDetail.variationIndex!.toString(),
        },
      });
    }
  };
}
