/**
 * Transmutes a key/value pairs object into the format expected by GraphQLEnumType
 * @param  {string} header header string of keys and values
 * @param  {string} delimeter symbol used to separate each key value pair from the next pair
 * @param  {string} valueDelimeter symbol used to separate the key from its value within each pair
 * @return {Object}     Entries object (key/value pairs)
 */
const reqHeaderToEntriesObj = (
  header: string,
  delimiter: string = ';',
  valueDelimeter: string = '='
) => {
  if (header === '') return {};
  return header.split(delimiter).reduce((accum: { [key: string]: string }, curVal) => {
    const obj = accum;
    const keyValuePair = curVal.split(valueDelimeter);
    const [keyValuePairKey, keyValuePairValue] = keyValuePair;
    obj[keyValuePairKey] = keyValuePairValue;
    return obj;
  }, {});
};

export default reqHeaderToEntriesObj;
