import { GtmServiceBase } from '@care/google-tag-manager';
import logger from '@/lib/clientLogger';

class GtmServiceExtended extends GtmServiceBase {}
// GtmService instantiated with clientLogger
export const GtmService = new GtmServiceExtended({
  logger,
});

export { GtmEvent } from '@care/google-tag-manager';
