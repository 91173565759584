import React, { useEffect } from 'react';
import { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import Router from 'next/router';
import getConfig from 'next/config';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { theme } from '@care/material-ui-theme';
import mediaQuery from 'css-mediaquery';
import Cookies from 'universal-cookie';
import { setUser } from '@sentry/nextjs';
import Script from 'next/script';
import { GtmHelper } from '@care/google-tag-manager';
import { GtmService, GtmEvent } from '@/utilities/GtmService';
import ApolloClient from '@/lib/ApolloClient';
import { AppStateProvider } from '@/components/contexts/AppState';
import { FeatureFlags, FeatureFlagsProvider } from '@/components/contexts/FeatureFlagsContext';
import logger from '@/lib/clientLogger';
import {
  CLIENT_FEATURE_FLAGS,
  CZEN_JSESSIONID_COOKIE_KEY,
  CZEN_SESSION_COOKIE_KEY,
  CZEN_VISITOR_COOKIE_KEY,
  SEO_PAGE_ID,
} from '@/constants';
import RXTrackingPixel from '@/components/shared/RXTrackingPixel/RXTrackingPixel';
import DefaultLayout from '@/components/layouts/DefaultLayout';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { insertMonetateScript } from '@/utilities/monetateHelper';
import reqHeaderToEntriesObj from '@/utilities/reqHeaderToEntriesObj';
import getDeviceCharacteristics from '@/utilities/getDeviceCharacteristics';
import sendWindowError from '@/utilities/sendWindowError';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
  RECAPTCHA_ENABLED,
  RECAPTCHA_V3_SITE_KEY: recaptchaSiteKey,
} = getConfig();

function getMeta() {
  return (
    <>
      <title>Care.com: Find Child Care, Babysitters, Senior Care, Pet Care and Housekeeping</title>
      <link rel="canonical" href={`${CZEN_GENERAL}/`} />
      <link rel="alternate" href={`${CZEN_GENERAL}/`} hrefLang="en-US" />
      <link rel="alternate" href={`${CZEN_GENERAL}/`} hrefLang="x-default" />
      <link rel="alternate" href={`${CZEN_GENERAL}/en-ca/`} hrefLang="en-CA" />
      <link rel="alternate" href={`${CZEN_GENERAL}/en-gb/`} hrefLang="en-GB" />
      <link rel="alternate" href={`${CZEN_GENERAL}/nl-nl/`} hrefLang="nl-NL" />
      <link rel="alternate" href={`${CZEN_GENERAL}/nl-be/`} hrefLang="nl-BE" />
      <link rel="alternate" href={`${CZEN_GENERAL}/fr-be/`} hrefLang="fr-BE" />
      <link rel="alternate" href={`${CZEN_GENERAL}/fr-fr/`} hrefLang="fr-FR" />
      <link rel="alternate" href={`${CZEN_GENERAL}/fr-ca/`} hrefLang="fr-CA" />
      <link rel="alternate" href={`${CZEN_GENERAL}/fi-fi/`} hrefLang="fi-FI" />
      <link rel="alternate" href={`${CZEN_GENERAL}/da-dk/`} hrefLang="da-dk" />
      <link rel="alternate" href={`${CZEN_GENERAL}/sv-se/`} hrefLang="sv-SE" />
      <link rel="alternate" href={`${CZEN_GENERAL}/de-ch/`} hrefLang="de-CH" />
      <link rel="alternate" href={`${CZEN_GENERAL}/en-ie/`} hrefLang="en-IE" />
      <link rel="alternate" href={`${CZEN_GENERAL}/en-au/`} hrefLang="en-AU" />
      <link rel="alternate" href={`${CZEN_GENERAL}/en-nz/`} hrefLang="en-NZ" />
      <link rel="alternate" href={`${CZEN_GENERAL}/no-no/`} hrefLang="no-NO" />
      <link rel="alternate" href={`${CZEN_GENERAL}/es-es/`} hrefLang="es-ES" />
      <link rel="alternate" href="https://www.betreut.at/" hrefLang="de-AT" />
      <link rel="alternate" href="https://www.betreut.de/" hrefLang="de-DE" />
      <link rel="icon" href={`${CZEN_GENERAL}/s/d/ttp/favicon-16x16.png`} />
      <link rel="image_src" href={`${CZEN_GENERAL}/img/care-no-tag.jpg`} />

      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta http-quiv="content-language" content="en-us" />
      <meta name="verify-v1" content="zz15lR180u9eCqTfur/9l+bMP75AampYtDEm6OnbkWY=" />
      <meta name="og:image" content={`${CZEN_GENERAL}/img/care-no-tag.jpg`} />
      <meta name="fb:page_id" content="6030284861" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="description"
        content="Get the help you need for your children, pets, elderly parents, home and lifestyle. Making it easier to find better care for your whole family."
      />
      <meta
        name="keywords"
        content="babysitter, nanny, child care, senior home care, sitter, care, date night"
      />
    </>
  );
}

type VhpAppProps = AppProps & {
  err: Error;
  ldClientFlags: FeatureFlags;
  czenVisitorId?: string;
  czenJSessionId?: string;
  czenSessionId?: string;
  referrerCookie?: string;
  url: string;
  careDeviceId: string;
  deviceChar: any;
  nonce?: string;
};

export default function App({
  Component,
  pageProps,
  router,
  ldClientFlags,
  err,
  czenVisitorId,
  czenJSessionId,
  czenSessionId,
  referrerCookie,
  url,
  careDeviceId,
  deviceChar,
  nonce,
}: VhpAppProps) {
  /* istanbul ignore next */
  const cache = createCache({ key: 'emotion-cache', nonce, prepend: true });

  useEffect(() => {
    async function pushGTMEvent() {
      await GtmService.pushDefinedEvent(GtmEvent.ViewedVhp, {
        sessionId: czenJSessionId,
      });
    }

    pushGTMEvent();
  }, [czenJSessionId]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
    });

    if (typeof window !== 'undefined') {
      // Next.js will automatically catch JS errors that happen during the rendering phase via _errors.tsx
      // for errors that may happen outside of rendering (i.e. part of a click handler), we need to rely on these events
      window.addEventListener('error', sendWindowError, false);
      window.addEventListener('unhandledrejection', sendWindowError, false);
      // eslint-disable-next-line no-underscore-dangle
      window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {};
    }
  }, []);

  useEffect(() => {
    AnalyticsHelper.setDefaultUserProps(careDeviceId);

    window.careAnalytics = AnalyticsHelper;

    if (router.asPath !== '/') {
      AnalyticsHelper.logEvent({
        name: 'Screen Viewed',
        data: {
          referer: document.referrer,
          rendered_by: 'vhp-mfe',
          czenVisitorId,
          czenJSessionId,
        },
      });
    }
  }, [czenVisitorId, czenJSessionId, router.asPath, careDeviceId]);

  useEffect(() => {
    if (router.pathname !== '/authCallback') {
      GtmHelper.pushFromSessionStorageUsingGtmService(GtmService);
    }
  }, [router.pathname]);

  // @ts-ignore
  const Layout = Component?.Layout || DefaultLayout;

  // load monetate after page load
  useEffect(() => {
    insertMonetateScript(router.pathname, url);
  }, [router.pathname, url]);

  // we're using `useRef` to ensure that RUM user attributes are set just once and before any child components are rendered
  const RUMUserInitializedRef = React.useRef<boolean>(false);
  if (!RUMUserInitializedRef.current) {
    setUser({
      id: careDeviceId,
      careDeviceId,
      czenSessionId,
      czenVisitorId,
    });

    RUMUserInitializedRef.current = true;
  }

  const ssrMatchMedia = (query: string) => {
    return {
      matches: mediaQuery.match(query, {
        // The estimated CSS width of the browser.
        width: deviceChar?.is_mobile === 'true' ? '1px' : '1400px',
      }),
    };
  };

  theme.components = {
    ...theme.components,
    // Change the default options of useMediaQuery
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia,
      },
    },
  };

  const recaptchaFlag = ldClientFlags[CLIENT_FEATURE_FLAGS.VHP_RECAPTCHA_ENABLED];
  const recaptchaFlagEnabled = recaptchaFlag?.value === true;

  return (
    <>
      <Head>
        {getMeta()}
        {RECAPTCHA_ENABLED && recaptchaFlagEnabled && (
          <Script
            strategy="lazyOnload"
            nonce={nonce}
            src={`https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`}
          />
        )}
      </Head>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ApolloProvider client={ApolloClient}>
            <FeatureFlagsProvider flags={ldClientFlags}>
              <AppStateProvider
                czenJSessionId={czenJSessionId}
                referrerCookie={referrerCookie}
                url={url}>
                <Layout>
                  {/* manually passing any errors here for _errors.tsx because https://github.com/vercel/next.js/issues/8592 */}
                  <Component {...pageProps} err={err} />
                  <RXTrackingPixel seoPageId={SEO_PAGE_ID} />
                </Layout>
              </AppStateProvider>
            </FeatureFlagsProvider>
          </ApolloProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

const isLocal = process.env.NODE_ENV === 'development';

/**
 * Invoked during SSR to build the map of data we need to provide to the client.  This function also serves to disable
 * static page generation in favor of SSR for *all* pages, which is currently needed in order to inject
 * the proper env-specific config at runtime rather than at build time.
 * @param appContext
 */
export async function getInitialProps(appContext: AppContext) {
  const {
    ctx: { req },
  } = appContext;
  let czenVisitorId;
  let czenJSessionId;
  let czenSessionId;
  let referrerCookie;
  let careDeviceId;
  const ldClientFlags: FeatureFlags = {};

  if (req) {
    // parse cookies from the request with a no-op decoder so we don't make any assumptions about how
    // the `rc` cookie is encoded by czen
    // TODO: Retrieve all cookies the flows care about, like n_tc and n_vis
    const cookies = new Cookies(req.headers.cookie, {
      decode: (c) => c,
    });
    const referrerCookieEncoded = cookies.get('rc');

    // TODO: remove the call to `decodeURIComponent` below after CAREUS-96857 has been addressed and we no longer
    // need to decode the cookie ourselves
    referrerCookie = referrerCookieEncoded ? decodeURIComponent(referrerCookieEncoded) : null;

    czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY) ?? undefined;
    czenJSessionId = cookies.get(CZEN_JSESSIONID_COOKIE_KEY) ?? undefined;
    czenSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY) ?? undefined;

    const { ldClient, ldUser } = req.careContext || {};
    ({ careDeviceId } = req);

    if (ldClient && ldUser) {
      const clientFlagPromises = Object.values(CLIENT_FEATURE_FLAGS).map(async (flagName) => {
        ldClientFlags[flagName] = await ldClient?.variationDetail(flagName, ldUser, undefined);
      });

      await Promise.all(clientFlagPromises);
    }
  }

  const url = req ? req.url : '';

  const userAgent = req?.headers['user-agent'] || '';
  const deviceCharHeader = req?.headers['x-akamai-device-characteristics']?.toString() || '';
  const deviceChar = isLocal
    ? getDeviceCharacteristics(userAgent, deviceCharHeader)
    : reqHeaderToEntriesObj(deviceCharHeader, ';');

  const { nonce } = req?.careContext || {};

  return {
    czenVisitorId,
    czenJSessionId,
    czenSessionId,
    referrerCookie,
    ldClientFlags,
    url,
    careDeviceId,
    deviceChar,
    nonce,
  };
}

App.getInitialProps = getInitialProps;
App.defaultProps = {
  czenVisitorId: undefined,
  czenJSessionId: undefined,
  referrerCookie: undefined,
};

/**
 * Enables Next.js' performance reporting:
 *
 * https://nextjs.org/docs/advanced-features/measuring-performance
 *
 * @param metric
 */
export function reportWebVitals(metric: NextWebVitalsMetric) {
  logger.info({
    tags: ['performance'],
    event: 'PerformanceReport',
    metric,
    location: window?.location.pathname,
  });
}
