import { ErrorEvent } from '@sentry/types';

export const shouldIgnoreTypeError = (event: ErrorEvent): boolean => {
  const typeErrorMessagesToIgnore = ['Load failed'];
  const fetchUrlsToIgnore = ['cdn.acsbapp.com'];
  const exception = event.exception?.values?.[0];

  // verify error is a type error and the message is in the list of messages to ignore before proceeding
  if (
    exception?.type !== 'TypeError' ||
    !event?.breadcrumbs ||
    !typeErrorMessagesToIgnore.includes(exception?.value as string)
  ) {
    return false;
  }

  // filter out fetch errors with urls that should be ignored
  const isFetchErrorToIgnore = (event?.breadcrumbs || []).some(
    (breadcrumb) =>
      breadcrumb?.level === 'error' &&
      breadcrumb?.category === 'fetch' &&
      fetchUrlsToIgnore.some((url) => breadcrumb?.data?.url?.includes(url))
  );

  return isFetchErrorToIgnore;
};
