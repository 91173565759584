import Cookies from 'universal-cookie';
// Monetate needs jQuery to work
import jquery from 'jquery';
import { EventPayload } from '@care/navigation/dist/components/types';
import { CZEN_VISITOR_COOKIE_KEY } from '@/constants';
import Logger from '@/lib/clientLogger';
import { parseURLParams } from '@/utilities/urlHelper';
import { AnalyticsHelper } from './AnalyticsHelper';

export const monetateTag: string = `
  var monetateT = new Date().getTime();
  (function() {
    var p = document.location.protocol;
    if (p == "http:" || p == "https:") {
      var m = document.createElement('script'); m.type = 'text/javascript'; m.async = true; m.src = (p == "https:" ? "https://s" : "http://") + "b.monetate.net/js/1/a-f5189428/p/care.com/" + Math.floor((monetateT + 828712) / 3600000) + "/g";
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(m, s);
    }
  })();
`;

export const monetateScript = (params: any) => `
  if (typeof czenMonetate == 'undefined') czenMonetate = {};
  if (typeof czenMonetate.page == 'undefined') czenMonetate.page = {};
  /* CAREUS-27868 (Monetate Attribute to Distinguish SMB Profile Type)
    note: this is a page attribute
  */
  czenMonetate.visitorId = '${new Cookies().get(CZEN_VISITOR_COOKIE_KEY) ?? ''}';
  czenMonetate.memberType = 'Visitor';
  czenMonetate.referrerLevel1 = '${params.rx1 ?? ''}';
  czenMonetate.referrerLevel2 = '${params.rx2 ?? ''}';
  czenMonetate.referrerLevel3 = '${params.rx3 ?? ''}';
  czenMonetate.referrerLevel4 = '${params.rx4 ?? ''}';
  czenMonetate.referrerLevel5 = '${params.rx5 ?? ''}';
  czenMonetate.referrerLevel6 = '${params.rx6 ?? ''}';
  czenMonetate.referrerLevel7 = '${params.rx7 ?? ''}';
  czenMonetate.referrerLevel8 = '${params.rx8 ?? ''}';
  czenMonetate.referrerLevel9 = '${params.rx9 ?? ''}';
  czenMonetate.referrerLevel10 = '${params.rx10 ?? ''}';
  czenMonetate.indicatedBUCIntent = '${params.indicatedBUCIntent ?? ''}';
  czenMonetate.stack = 'dom-use-prodwebapp-276.use.dom.carezen.net';
  czenMonetate.hideMonetateBanner = '${params.hideMonetateBanner ?? ''}';
  czenMonetate.isCsrSession = '${params.isCsrSession ?? ''}';
`;

export const insertMonetateScript = (pathname: string, url: string) => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.$ = jquery;
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.jQuery = jquery;

  // @ts-ignore
  if (pathname === '/') {
    window.require = (
      _: string[],
      callback: (analytics: { logEvent: (payload: EventPayload) => void }) => void
    ) => {
      const fakeCareAnalytics = {
        logEvent: (payload: EventPayload) => {
          // Log warning about monetate
          Logger.warn({
            key: 'UnstableMonetateScript',
            event: 'SimpleLog',
            source: 'Monetate.tsx',
            amplitude_payload: payload,
          });

          // Fire amplitude event
          AnalyticsHelper.logEvent(payload);
        },
      };

      callback(fakeCareAnalytics);
    };

    const mTag = document.getElementById('monetateTag');
    const mScript = document.getElementById('monetateScript');

    if (mTag) {
      mTag.innerHTML = monetateTag;
      document.head.appendChild(mTag);
    }

    if (mScript) {
      const urlParams = parseURLParams(decodeURIComponent(url));
      mScript.innerHTML = monetateScript(urlParams);
      document.head.appendChild(mScript);
    }
  }
};
