import { CLIENT_FEATURE_FLAGS } from '@/constants';
import { useFeatureFlags } from '../contexts/FeatureFlagsContext';

const useTestFlags = () => {
  const featureFlags = useFeatureFlags();
  const safetyBanner = featureFlags?.flags[CLIENT_FEATURE_FLAGS.SAFETY_BANNER];
  const navLoginLink = featureFlags?.flags[CLIENT_FEATURE_FLAGS.NAV_LOGIN_LINK]?.value ?? false;
  const ttpAssetUrl = featureFlags?.flags[CLIENT_FEATURE_FLAGS.TTP_ASSET_URL];
  const vhpFontSelector = featureFlags?.flags[CLIENT_FEATURE_FLAGS.VHP_FONT_SELECTOR];
  const vhpVerticalHover = featureFlags?.flags[CLIENT_FEATURE_FLAGS.VHP_VERTICAL_HOVER];
  const seniorCareRedirection = featureFlags.flags[CLIENT_FEATURE_FLAGS.SC_PROVIDER_ENROLLMENT];
  const vhpTriageProviderVertical =
    featureFlags.flags[CLIENT_FEATURE_FLAGS.VHP_TRIAGE_PROVIDER_VERTICAL];
  const applyToJobsModal = featureFlags.flags[CLIENT_FEATURE_FLAGS.APPLY_TO_JOBS_MODAL];
  const vhpAllListYourBusinessCTA =
    featureFlags.flags[CLIENT_FEATURE_FLAGS.GROWTH_VHP_ALL_LIST_YOUR_BUSINESS_CTA];
  const vhpMilestone1 = featureFlags.flags[CLIENT_FEATURE_FLAGS.VHP_2_0_MILESTONE_1];
  const rtxTrackingLazyLoad = featureFlags.flags[CLIENT_FEATURE_FLAGS.RTX_TRACKING_LAZY_LOAD];
  const adultCareTest = featureFlags.flags[CLIENT_FEATURE_FLAGS.GROWTH_VHP_ADULT_CARE];

  return {
    safetyBanner,
    navLoginLink,
    ttpAssetUrl,
    vhpFontSelector,
    vhpVerticalHover,
    seniorCareRedirection,
    applyToJobsModal,
    vhpTriageProviderVertical,
    vhpAllListYourBusinessCTA,
    vhpMilestone1,
    rtxTrackingLazyLoad,
    adultCareTest,
  };
};

export default useTestFlags;
