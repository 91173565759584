import { LDEvaluationDetail } from 'launchdarkly-node-server-sdk';
import { createContext, useContext, ReactNode, useState } from 'react';

interface FeatureFlagsProviderProps {
  children: ReactNode;
  flags: FeatureFlags;
}

type FeatureFlagsState = {
  flags: FeatureFlags;
};

const initialState = {
  flags: {},
};

export type FeatureFlags = Record<string, LDEvaluationDetail>;

const FeatureFlagsContext = createContext<FeatureFlagsState>(initialState);

export const FeatureFlagsProvider = ({ children, flags }: FeatureFlagsProviderProps) => {
  const [state] = useState({ flags });

  return <FeatureFlagsContext.Provider value={state}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
