import { createContext, useReducer, useContext, ReactNode } from 'react';
import { debounce } from 'lodash-es';
import { initialAppState, rootReducer } from '@/state';
import { SafeLocalStorage } from '@/utilities/localStorageHelper';
import { LOCAL_STORAGE_STATE_KEY } from '@/constants';
import { AppAction, AppDispatch, AppState } from '@/types/AppState';

function getStateFromStorageOrDefault(defaultState: AppState) {
  const stateFromStorageJson = SafeLocalStorage.getInstance().getItem(LOCAL_STORAGE_STATE_KEY);
  if (stateFromStorageJson) {
    return JSON.parse(stateFromStorageJson) as AppState;
  }
  return defaultState;
}

function persistState(state: AppState) {
  SafeLocalStorage.getInstance().setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(state));
}

const debouncePersistState = debounce(persistState, 500, {
  leading: false,
  trailing: true,
  maxWait: 1000,
});

function reducerWrapper(state: AppState, action: AppAction) {
  const nextState = rootReducer(state, action);
  debouncePersistState(nextState);
  return nextState;
}

const AppStateContext = createContext<AppState>(initialAppState);
const AppDispatchContext = createContext<AppDispatch>(() =>
  // eslint-disable-next-line no-console
  console.warn(
    'Calling default dispatch is a no-op! You are probably consuming app dispatch in a component above AppStateProvider'
  )
);

type AppStateProviderProps = {
  children: ReactNode;
  initialStateOverride?: AppState;
  czenJSessionId?: string;
  referrerCookie?: string;
  url?: string;
};

export const AppStateProvider = ({
  children,
  initialStateOverride,
  czenJSessionId,
  referrerCookie,
  url,
}: AppStateProviderProps) => {
  const hydratedInitialAppState = {
    ...initialAppState,
  };
  const [state, dispatch] = useReducer(
    reducerWrapper,
    {
      czenJSessionId,
      referrerCookie,
      ...(initialStateOverride ?? hydratedInitialAppState),
      url,
    },
    getStateFromStorageOrDefault
  );

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

AppStateProvider.defaultProps = {
  initialStateOverride: undefined,
  czenJSessionId: undefined,
  referrerCookie: undefined,
  url: '',
};

export const useAppState = () => useContext(AppStateContext);
export const useAppDispatch = () => useContext(AppDispatchContext);
