/* List of possible parameters (for testing)
Promo code:
  Parameter: `cx`
  List of codes:
    JOINCARE20
    JOINCARE15
    LIFEMART30
    CAREPERK20
    GOOGLE20
    BING20
    MEGHAN20
    CAROLINE20
    DANA20
    TAMARA20
    KERMILIA20
  Usage: `http://localhost:3000/app/vhp?cx=KERMILIA20`

Referrer tracking:
  Parameter: `rx<LEVEL_INDEX> = <VALUE>`
  Examples of <VALUE>:
    online_affiliates
    seeker_general
    impact
    homepage_text_link
    sem_google
  Examples of <LEVEL_INDEX>: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
  Usage: `http://localhost:3000/app/vhp?rx1=sem_google`
*/

type URLParams = { [key: string]: string };

// eslint-disable-next-line import/prefer-default-export
export function parseURLParams(url?: string) {
  const urlParams: URLParams = {};

  // For SSR you should always use `const { url } = useAppState();` in component and pass the URL to this function. This is only a fallback for unexpected cases.
  const searchArgs = typeof window === 'object' ? window.location.search : '';
  let paramString = url || searchArgs;
  if (paramString && paramString[0] === '?') {
    paramString = paramString.substring(1);
  }

  // added for tests
  if (!paramString) {
    return {
      cx: '',
    };
  }
  paramString = paramString.replace(/[?]/gi, '&');
  const searchParams = new URLSearchParams(paramString);

  searchParams.forEach((value, key) => {
    urlParams[key as string] = value;
  });

  urlParams.cx = urlParams.cx?.toUpperCase();

  return urlParams;
}
