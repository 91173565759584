import { useEffect, useState } from 'react';
import useTestFlags from '@/components/hooks/useTestFlags';
import { getRXTrackingUrl } from './utils';

interface RXTrackingPixelProps {
  seoPageId: number;
}

function RXTrackingPixel({ seoPageId }: RXTrackingPixelProps) {
  const [RXTrackingURL, setRXTrackingURL] = useState<string>();

  const { rtxTrackingLazyLoad } = useTestFlags();

  useEffect(() => {
    /* istanbul ignore next */
    if (rtxTrackingLazyLoad?.value) {
      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(() => {
          setRXTrackingURL(getRXTrackingUrl(seoPageId));
        });
      } else {
        // Fallback for browsers that don't support requestIdleCallback
        setTimeout(() => {
          setRXTrackingURL(getRXTrackingUrl(seoPageId));
        }, 2000);
      }
    } else {
      setRXTrackingURL(getRXTrackingUrl(seoPageId));
    }
  }, [seoPageId, rtxTrackingLazyLoad]);

  if (!RXTrackingURL) {
    return null;
  }

  return <img src={RXTrackingURL} width={1} height={1} alt="" style={{ display: 'none' }} />;
}

export default RXTrackingPixel;
