/**
 * @returns CZEN Rx tracking URL. Example: '/track/clickAccessLog.do?rx=campaignOne&t_seoPageId=1001&t_url=/app/vhp'
 */
export function getRXTrackingUrl(seoPageId: number) {
  const tracingURL = '/track/clickAccessLog.do';
  const { referrer } = document;
  const url = new URL(window.location.href);

  let trackUrl = tracingURL;
  trackUrl += url.search ? `${url.search}&` : '?';
  trackUrl += `t_seoPageId=${seoPageId}`;
  if (referrer) {
    trackUrl += `&t_referer=${referrer}`;
  }
  trackUrl += `&t_url=${url.pathname}`;

  return trackUrl;
}
