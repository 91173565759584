import logger from '../lib/clientLogger';
import { CLIENT_SIDE_ERROR_TAG } from '../constants';

function sendWindowError(windowEvent: ErrorEvent | PromiseRejectionEvent) {
  const error =
    (windowEvent as ErrorEvent)?.error ?? (windowEvent as PromiseRejectionEvent)?.reason;
  if (error) {
    logger.error({
      tags: [CLIENT_SIDE_ERROR_TAG],
      error: String(error),
    });
  }
}

export default sendWindowError;
